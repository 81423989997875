<!--
 * @Description:停车缴费-停车代缴 payForBehalf
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 11:49:06
 * @LastEditors: zhoucheng
-->
<template>
  <div class='main'>
    <van-nav-bar title="停车代缴"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack">
    </van-nav-bar>
    <div class="topBack"></div>
    <div class="main-content">
      <div class="content">
        <van-row class="titleTop">请输入完整的车牌号</van-row>
        <div class="platInput-box">
          <plate-number-input ref="numInput"
                              v-model="plateNumber"
                              @idxChange='handleClickChangeIndex'
                              @currentPlateNumber='handleShowPlateNumber' />
        </div>
        <van-row class="title">车牌颜色</van-row>
        <van-row class="plateColorDiv">
          <van-row class="plateColorItem"
                   v-for="(item,index) in plateColorList"
                   :key="index"
                   :style="item.code==numberPlateColorCode?'border:1px solid #2FA4E5':''"
                   @click="handleSelect(item)">
            <van-row class="itemLeft"
                     :style="{'background':colorJson[item.name]}"></van-row>
            <span style="margin-left:5px">{{item.name}}</span>
          </van-row>
        </van-row>
      </div>
    </div>
    <div class="but-box">
      <van-button @click="handleAdd"
                  :disabled="buttonDisable">确 认</van-button>
    </div>
    <keyborad-button ref="keyboard"
                     class="keyboradButton"
                     v-show="popShow"
                     @handleClickClose="handleClickClose"
                     @handleClickRemoveAll="handleClickRemoveAll"
                     @click="handleKeyboardClick" />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import plateNumberInput from '@/components/plateNumber/plateNumberInput'
import keyboradButton from '@/components/plateNumber/keyboradButton'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    plateNumberInput,
    keyboradButton
  },
  data () {
    // 这里存放数据
    return {
      plateColorList: [], // 车牌颜色列表
      colorJson: {
        蓝色: '#5A6DF4 100%',
        绿色: '#009017 100%',
        黑色: '#262628 100%',
        黄色: '#F6C210 100%',
        白色: '#C8CAD8 100%'
      },
      plateColor: '蓝色', // 车牌颜色
      numberPlateColorCode: '2', // 默认蓝色车牌编码
      plateNumber: '', // 车牌号
      popShow: false, // 车牌键盘显示
      buttonDisable: true // 确认按钮
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    plateNumber () {
      if (this.plateNumber.length < 7) {
        this.buttonDisable = true
        this.numberPlateColorCode = '2'
      }
      if (this.plateNumber.length >= 7) {
        this.buttonDisable = false
      }
      if (this.plateNumber.length === 8) {
        this.plateColor = '绿色'
        this.numberPlateColorCode = '6'
      }
      if (this.plateNumber.length !== 8) {
        this.plateColor = '蓝色'
        this.numberPlateColorCode = '2'
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  mounted () {
    this.$refs.numInput.setInput('渝')
    this.getColorDict()
  },
  // 方法集合
  methods: {
    // 关闭车牌
    handleClickClose () {
      this.popShow = false
    },
    // 清空车牌
    handleClickRemoveAll () {
      this.$refs.numInput.handleClickRemovePlateNumber()
      this.plateNumber = ''
      this.plateColor = '蓝色'
      this.numberPlateColorCode = '2'
    },
    // 获取到车牌的值
    handleShowPlateNumber (val) {
      this.plateNumber = val
    },
    // 获取车牌输入框的 index
    handleClickChangeIndex (val) {
      this.popShow = true
      this.$refs.keyboard.handleTypeChange(val)
    },
    // 键盘点击事件
    handleKeyboardClick (value) {
      this.$refs.numInput.setInput(value) // 将点击的值传入父组件
    },
    // 选取车牌颜色
    handleSelect (item) {
      if (this.plateNumber.length === 8) {
        this.plateColor = '绿色'
        this.numberPlateColorCode = '6'
      } else {
        this.plateColor = item.name
        this.numberPlateColorCode = item.code
      }
    },
    // 确认按钮
    handleAdd () {
      if (this.plateNumber.length !== 8 && this.numberPlateColorCode === '6') {
        this.$toast('请选择正确的车牌颜色')
      } else {
        this.getOrderList()
      }
    },
    // 新版 获取订单详情列表
    getOrderList () {
      const info = {
        plateNumber: this.plateNumber,
        numberPlateColorCode: this.numberPlateColorCode
      }
      this.$payOnBehalfDetails.queryInAndLackOrder(info).then(res => {
        if (res.resultEntity) {
          this.$router.push({
            name: 'payOnBehalfDetails',
            query: {
              plateNumber: this.plateNumber,
              plateColor: this.plateColor,
              numberPlateColorCode: this.numberPlateColorCode
            }
          })
        } else {
          this.$toast('该车不存在在停或欠费订单')
        }
      })
    },
    // 老版 获取订单详情列表
    // getOrderList () {
    //   const info = {
    //     plateNumber: this.plateNumber,
    //     numberPlateColorCode: this.numberPlateColorCode
    //   }
    //   this.$payForParkCount.queryOnlineOrderBySinglePlateNumber(info).then(res => {
    //     if (res.resultEntity) {
    //       this.$router.push({
    //         name: 'parForParkCount',
    //         query: { plateNumber: this.plateNumber, numberPlateColorCode: this.numberPlateColorCode }
    //       })
    //     } else {
    //       this.$toast('该车未停!')
    //     }
    //   })
    // },
    // 获取车牌颜色
    getColorDict () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2'
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.plateColorList = res.resultEntity.filter(item => {
          if (item.code !== '4' && item.code !== '5') {
            return item
          }
        })
      })
    },
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
.main {
  width: 100%;
  height: 100%;
  position: relative;
  background: #f5f6f8 100%;
  overflow: hidden;
  position: relative;
  .topBack {
    width: 100%;
    height: 180px;
    background-image: url(../../../assets/plateNumber/background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
  }
  .main-content {
    width: 92%;
    height: 30%;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left: 4%;
    margin-top: 120px;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 95%;
      .titleTop {
        width: 100%;
        height: 30px;
        line-height: 30px;
        margin-top: 6%;
        padding-left: 4.5%;
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #333333;
        letter-spacing: 0;
      }
      .title {
        width: 100%;
        height: 30px;
        line-height: 30px;
        margin-top: 2%;
        padding-left: 4.5%;
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #666666;
        letter-spacing: 0;
      }
      .platInput-box {
        width: 95%;
        height: 55px;
        margin-top: 4%;
      }
      .plateColorDiv {
        width: 100%;
        height: 30px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin: 5px 0 0 5%;
        .plateColorItem {
          width: 17.5%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          margin-left: 1.5%;
          font-family: PingFangSC-Regular;
          font-size: 13px;
          color: #333333;
          letter-spacing: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #dbdbdb;
        }
        .itemLeft {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }
  }

  .but-box {
    width: 84%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8%;
    margin-top: 5%;
    .van-button {
      width: 100%;
      border-radius: 25px;
      background: #2fa4e5;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 3px;
    }
  }
  .keyboradButton {
    width: 100%;
    height: 250px;
    position: absolute;
    bottom: 0;
    border-radius: 15px;
    background: #e2e3e7;
    display: block;
    z-index: 999;
  }
}
</style>
